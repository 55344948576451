import React from "react";
import "./SideMenu.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function SideMenu({ activeIndex, setActiveIndex, currRole }) {
  const menuItems = [
    {
      icon: "fa-solid fa-chart-line",
      tooltip: "Dashboard",
      access: ["GBO", "GS", "SYSADMIN"],
    },
    { icon: "fa-solid fa-users", tooltip: "Users", access: ["GS", "SYSADMIN"] },
    {
      icon: "fa-solid fa-people-group",
      tooltip: "Communities",
      access: ["GBO","GC","GS", "SYSADMIN"],
    },
    {
      icon: "fa-solid fa-calendar-check",
      tooltip: "Events",
      access: ["GBO","GS", "SYSADMIN"],
    },
    {
      icon: "fa-solid fa-triangle-exclamation",
      tooltip: "Reports",
      access: ["GBO","GS", "SYSADMIN"],
    },
    {
      icon: currRole == "EP" ? "fa-solid fa-ticket" : "fa-solid fa-handshake",
      tooltip: currRole == "EP" ? "Coupons": "Partners",
      access: ["GS", "SYSADMIN","GBO","EP"],
    },
  ];

  const bottomMenuItems = [
    { icon: "fa-solid fa-map-location-dot", tooltip: "Map" },
    { icon: "fa-solid fa-circle-user", tooltip: "Account" },
    {
      icon: "fa-solid fa-arrow-right-from-bracket",
      tooltip: "Logout",
    },
  ];

  const handleMenuItemClick = (index) => {
    setActiveIndex(index);
  };

  const hasAccess = menuItems.some(item => item.access.includes(currRole));

  return (
    <nav className="sidebar-navigation">
      <ul className="topItems">
        {hasAccess
          ? menuItems.map((item, index) =>
              item.access.includes(currRole) ? (
                <li
                  key={index}
                  className={index === activeIndex ? "active" : ""}
                  onClick={() => handleMenuItemClick(index)}
                >
                  <div className="menu-item-container">
                    <i className={item.icon}></i>
                  </div>
                  {item.tooltip && (
                    <span className="tooltip">{item.tooltip}</span>
                  )}
                </li>
              ) : null
            )
          : bottomMenuItems.map((item, index) => (
              <li
                key={index + menuItems.length}
                className={index + menuItems.length === activeIndex ? "active" : ""}
                onClick={() => handleMenuItemClick(index + menuItems.length)}
              >
                <div className="menu-item-container">
                  <i className={item.icon}></i>
                </div>
                {item.tooltip && (
                  <span className="tooltip">{item.tooltip}</span>
                )}
              </li>
            ))}
      </ul>

      {hasAccess && (
        <ul className="bottomItems">
          {bottomMenuItems.map((item, index) => (
            <li
              key={index + menuItems.length}
              className={index + menuItems.length === activeIndex ? "active" : ""}
              onClick={() => handleMenuItemClick(index + menuItems.length)}
            >
              <div className="menu-item-container">
                <i className={item.icon}></i>
              </div>
              {item.tooltip && (
                <span className="tooltip">{item.tooltip}</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
}

export default SideMenu;
