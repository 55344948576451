import './couponsPage.css'

import { Coupons} from "../../../lib/api";
import { AuthContext } from "../../../App";
import PopUp from "./PopUp";
import React, { useState, useEffect, useContext } from "react";

function CouponsPage() {
    const couponsApi = new Coupons();

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupError, setPopupError] = useState(false);
    const [loading, setLoading] = useState(false);
  
  
    const [popupType, setPopupType] = useState("");
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    
    const currentAuth = useContext(AuthContext);

    const[coupons, setCoupons] = useState([]);
  
    async function getAllCoupons() {
      setLoading(true);
      try {
        const response = await couponsApi.getCoupons();
        setCoupons(response.data);
      } catch (error) {
        console.error("Failed to get users:", error);
      } finally {
        setLoading(false);
      }
    }
  
    useEffect(() => {
      getAllCoupons();
    }, []);
  

    const handleEditClick = (type) => {
      setPopupType(type);
      setIsPopupVisible(true);
    };
  
    const handleClosePopup = () => {
      setIsPopupVisible(false);
      getAllCoupons();  
    };
  
  
    return (
      <>{loading ? 
        <div className="loading-screen">
        <i className="fa fa-spinner fa-spin"></i> 
        <p>Loading</p>
      </div>
     : <>   
      <div className="coupons-list">
        {showPopup && (
          <div className={`pop-up ${popupError ? "error" : ""}`}>
            {popupMessage}
          </div>
        )}
        <div className="coupons-list-container">
          <div className="coupons-page-container">
            <div className="user-options">
              <h1>Coupons</h1>
              <div className="search-div">
                {/* <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  className="searchBar"
                  type="search"
                  placeholder="Search"
                ></input> */}
                 <button className="refresh" onClick={()=>{getAllCoupons()}}>
                <i class="fa-solid fa-refresh"></i>
              </button>
                  <button className="addUserBtn" onClick={()=>handleEditClick("addCoupon")}>
                <i class="fa-solid fa-plus"></i>
              </button>
              </div>
            </div>
  
     
              <div className="coupons-table">
                <table className="statsTable">
                  <thead>
                    <tr>
                    {currentAuth.role !== "EP" &&<th>
                        <span className="sortBtn">
                          ID
                          <i className="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                      </th>}
                      <th>
                        <span className="sortBtn">
                          Description
                          <i className="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                      </th>
                      <th>
                        <span className="sortBtn">
                          points
                          <i className="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                      </th>
                      <th>
                        <span className="sortBtn">
                          Expiration Date
                          <i className="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                      </th>
                      <th>
                        <span className="sortBtn">
                          Photo
                          <i className="fa-solid fa-arrow-down-wide-short"></i>
                        </span>
                      </th>
                      <th>
                        <span className="sortBtn">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupons.map((coupon, index) => (
                      <tr key={index}>
                        {currentAuth.role !== "EP" && <td>{coupon.couponId}</td>}
                        <td>{coupon.description}</td>
                        <td>{coupon.points}</td>
                        <td>{coupon.expirationDate}</td>
                        <td><img className='qrcode-coupon' src={coupon.photoUrl}/></td>
                        <td>
                          <div className="actions-btns">
                            <button
                              className="removeBtn"
                              onClick={async () => {
                                await couponsApi.deleteCoupon(coupon.couponId);
                                getAllCoupons();
                              }}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            
          </div>
        </div>
        {isPopupVisible && (
          <PopUp
            type={popupType}
            currentUser={currentAuth.user}
            onClose={handleClosePopup}
          />
        )}
      </div>
      </>
      }
      </>
    );
}

export default CouponsPage