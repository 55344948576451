import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {AuthContext} from '../../App.js'

function RequireLogoutRoute ({ children }) {

    const navigate = useNavigate();

    const currentAuth = useContext(AuthContext);
  
    useEffect(()=>{
        if (currentAuth.token) {
            navigate("/system-management");
          }
    },[currentAuth])
  
    return <>{(!currentAuth.token) ? children : <></>}</>;
  };

export default RequireLogoutRoute;