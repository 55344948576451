import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";

import {AuthContext} from '../../App'

function RequireLoginRoute ({ children }) {

    const navigate = useNavigate();

    const currentAuth = useContext(AuthContext);

  
    useEffect(()=>{
        if (!currentAuth.token) {
          navigate("/login",{replace:true})
        }

    },[currentAuth])
  
    return <>{currentAuth.token ? children : <></>}</>;
  };

export default RequireLoginRoute;