import React, { useState, useEffect, useContext } from "react";
import "./selectedCommunity.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Communities, Users } from "../../../lib/api";
import { AuthContext } from "../../../App.js";
import PopUp from "./PopUp";

function SelectedCommunity() {
  const location = useLocation();
  var { selectedCommunity } = location.state;

  const navigate = useNavigate();

  const communitiesApi = new Communities();
  const usersApi = new Users();
  const currentAuth = useContext(AuthContext);
  var currUser = currentAuth.user;
  var currUserRole = currentAuth.user.role;

  const [communityUsers, setCommunityUsers] = useState([]);
  const [communityPoints, setCommunityPoints] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [showUserSelect, setShowUserSelect] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupError, setPopupError] = useState(false);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(currentAuth && currentAuth.user){
     setLoading(false)
    }
  }, [currentAuth]);

  function hasEditPermissions() {
    return currUserRole === "GC" || currUserRole === "GS" || currUserRole === "SYSADMIN";
  }

  console.log(hasEditPermissions())

  console.log("USERRRR:" , currUser)

  const [userToPromote, setUserToPromote] = useState(null);

  const [popupType, setPopupType] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleEditClick = (type) => {
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    selectedCommunity = JSON.parse(localStorage.getItem("selectedCommunity"));
    console.log(selectedCommunity);
  };

  async function removeUserFromCommunity(userId) {
    try {
      await communitiesApi
        .removeUserFromCommunity(selectedCommunity.id, userId)
        .then(() => {
          const updatedUsers = communityUsers.filter(
            (user) => user.id !== userId
          );
          setCommunityUsers(updatedUsers);
          setCommunityPoints(selectedCommunity.points);
          setPopupMessage("User removed successfully");
          setShowPopup(true);
        });
      setTimeout(() => setShowPopup(false), 3500);
    } catch (error) {
      setPopupMessage(error.response.data.error);
      setShowPopup(true);
      setPopupError(true);
      setTimeout(() => setShowPopup(false), 3500);
    }
  }

  async function addUsersToCommunity() {
    try {
      const userIds = Array.from(selectedUsers);
      await Promise.all(
        userIds.map((userId) =>
          communitiesApi.addUserToCommunity(selectedCommunity.id, userId)
        )
      );
      const addedUsers = allUsers.filter((user) => selectedUsers.has(user.id));
      setCommunityUsers((prevUsers) => [...prevUsers, ...addedUsers]);
      setCommunityPoints(selectedCommunity.points);
      setShowUserSelect(!showUserSelect);
      if (selectedUsers.size > 1) setPopupMessage("Users added successfully");
      else setPopupMessage("User added successfully");
      setShowPopup(true);
      setPopupError(false);
      setTimeout(() => setShowPopup(false), 3500);
    } catch (error) {
      setPopupMessage(error.response.data.error);
      setShowPopup(true);
      setPopupError(true);
      setTimeout(() => setShowPopup(false), 3500);
    }
  }

  async function deleteCommunity() {
    try {
      communitiesApi.deleteCommunity(selectedCommunity.id).then(() => {
        document.getElementById("backBtn").click();
      });
      setPopupMessage("Community deleted successfully");
      setShowPopup(true);
      setPopupError(false);
      setTimeout(() => setShowPopup(false), 3500);
    } catch (error) {
      setPopupMessage(error.response.data.error);
      setShowPopup(true);
      setPopupError(true);
      setTimeout(() => setShowPopup(false), 3500);
    }
  }

  const handleUserCheckboxChange = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = new Set(prevSelectedUsers);
      if (newSelectedUsers.has(userId)) {
        newSelectedUsers.delete(userId);
      } else {
        newSelectedUsers.add(userId);
      }
      return newSelectedUsers;
    });
  };

  async function getSelectedCommunityUsers() {
    try {
      if (selectedCommunity) {
        const response = await communitiesApi.getCommunityUsers(
          selectedCommunity.id
        );
        setCommunityUsers(response.data);
      }
    } catch (error) {
      console.error("Failed to get community users:", error);
    }
  }

  async function getAllUsers() {
    try {
      const response = await usersApi.getAllUsers();
      setAllUsers(response.data);
    } catch (error) {
      console.error("Failed to get all users:", error);
    }
  }

  useEffect(() => {
    getSelectedCommunityUsers();
    getAllUsers();
  }, [selectedCommunity]);

  useEffect(() => {
    setCommunityPoints(selectedCommunity.points);
  }, [communityUsers]);

  function parseRole(role) {
    switch (role) {
      case "SYSADMIN":
        return "System Admin";
      case "GS":
        return "System Manager";
      case "GBO":
        return "Backoffice Manager";
      case "GC":
        return "Community Manager";
      case "EP":
        return "External Partner";
      case "USER":
        return "User";
      case "ADMIN":
        return "Administrator";
      default:
        return "User";
    }
  }

  if (!selectedCommunity) return <div>Loading...</div>;

  return (
    <div className="selected-community-list">
      <div className="selected-community-container">
        <div className="selected-community-page">
          <div className="backBtn-div">
            <button
              id="backBtn"
              onClick={() => navigate("/system-management/communities")}
              className="back-button"
            >
              <i className="fa-solid fa-arrow-left-long"></i>
              Back
            </button>
          </div>
          <div className="selected-community-top-bar">
            <div className="selected-community-profile">
              <div className="selected-community-image">
                <img src={selectedCommunity.photoUrl} alt="Community" />
              </div>
              <div className="selected-community-info">
                <h1>{selectedCommunity.name}</h1>
                <h3>{selectedCommunity.description}</h3>
                <p>Members: {communityUsers.length}</p>
                <p>Points: {selectedCommunity.points}</p>
              </div>
            </div>

            {hasEditPermissions() && (
              <div className="edit-community">
                <div className="editBtns">
                  <button
                    className="editBtn"
                    onClick={() => {
                      handleEditClick("editCommunity");
                    }}
                  >
                    <i className="fa-solid fa-pen"></i>
                  </button>
                  <button
                    className="addUserBtn"
                    onClick={() => setShowUserSelect(!showUserSelect)}
                  >
                    <i className="fa-solid fa-user-plus"></i>
                  </button>
                  <button
                    className="addEventBtn"
                    onClick={() => handleEditClick("addEvent")}
                  >
                    <i className="fa-solid fa-calendar"></i>
                  </button>
                  <button
                    className="deleteBtn"
                    onClick={() => deleteCommunity()}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </div>
                <div className="search-bar-container">
                  <input
                    className="searchBar"
                    type="search"
                    placeholder="Search"
                  ></input>
                </div>
              </div>
            )}
          </div>
          {showUserSelect && (
            <div className="user-select-list">
              <div className="users-list-title">
                <h1>Users</h1>
                <i
                  className="fa-solid fa-xmark"
                  onClick={() => setShowUserSelect(!showUserSelect)}
                ></i>
              </div>

              <div className="user-select-option-list">
                {allUsers.map((user) => (
                  <div key={user.id} className="user-select-option">
                    <label htmlFor={`user-${user.id}`}>
                      {user.name} ({user.username})
                    </label>
                    <input
                      type="checkbox"
                      id={`user-${user.id}`}
                      checked={selectedUsers.has(user.id)}
                      onChange={() => handleUserCheckboxChange(user.id)}
                      className="custom-checkbox"
                    />
                  </div>
                ))}
              </div>

              <button onClick={addUsersToCommunity}>Add Selected Users</button>
            </div>
          )}
          <div className="selected-community-table">
            <table className="statsTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Username</th>
                  {hasEditPermissions() && <th>Email</th>}
                  <th>Role</th>
                  <th>Points</th>
                  <th>Level</th>
                  {hasEditPermissions() && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {communityUsers.map((user, index) => (
                  console.log(user),
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    {hasEditPermissions() && <td>{user.email}</td>}
                    <td>{parseRole(user.roleInsideCommunity)}</td>
                    <td>{user.points}</td>
                    <td>{user.level.toString().split('.')[0]}</td>
                    {hasEditPermissions() && (
                      <td>
                        <div className="actions-btns">
                          <button
                            className="removeBtn"
                            onClick={() => removeUserFromCommunity(user.id)}
                          >
                            <i className="fa-solid fa-user-minus"></i>
                          </button>
                          <button
                            className="promoteBtn"
                            onClick={async () => {
                              await communitiesApi.promote(
                                user.id,
                                selectedCommunity.id
                              );
                              getSelectedCommunityUsers();
                            }}
                          >
                            <i className="fa-solid fa-turn-up"></i>
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className="pagination">
              <button className="previous-page-btn">
                <i className="fa-solid fa-caret-left"></i> Previous
              </button>
              <p>Page 1 of 2</p>
              <button className="next-page-btn">
                Next <i className="fa-solid fa-caret-right"></i>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className={`pop-up ${popupError ? "error" : ""}`}>
          {popupMessage}
        </div>
      )}
      {isPopupVisible && (
        <PopUp
          type={popupType}
          currentUser={currUser}
          currentCommunity={selectedCommunity}
          userToPromote={userToPromote}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}

export default SelectedCommunity;
