import React, { useEffect, useState } from "react";
import {
  Communities,
  Users,
  Reports,
  Images,
  Events,
  Coupons,
  Auth,
} from "../../../lib/api";
import "./popup.css";
import Loader from "../../../views/Loader";

function PopUp({
  type,
  currentUser,
  currentCommunity,
  currentEvent,
  onClose,
  userToPromote,
}) {
  const authApi = new Auth();
  const usersApi = new Users();
  const communitiesApi = new Communities();
  const eventsApi = new Events();
  const reportsApi = new Reports();
  const couponsApi = new Coupons();
  const images = new Images();

  // State Initialization
  const [name, setName] = useState(currentUser?.name || "");
  const [username, setUsername] = useState(currentUser?.username || "");
  const [photoUrl, setPhotoUrl] = useState(currentUser?.photoUrl || "");
  const [isPublic, setIsPublic] = useState(currentUser?.isPublic || false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [communityName, setCommunityName] = useState(
    currentCommunity?.name || ""
  );
  const [communityDescription, setCommunityDescription] = useState(
    currentCommunity?.description || ""
  );
  const [communityPhotoUrl, setCommunityPhotoUrl] = useState(
    currentCommunity?.photoUrl || ""
  );
  const [communityIsPublic, setCommunityIsPublic] = useState(
    currentCommunity?.isPublic || false
  );

  const [loadingQR, setLoadingQR] = useState(true);
  const [mfaStep, setMfaStep] = useState(0);
  const [qrCode, setQrCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (type === "enableMFA") {
      authApi
        .generateNewMFA()
        .then((res) => {
          setQrCode(res.data.qrCode);
          setLoadingQR(false);
        })
        .catch((err) => {
          console.error("Failed to generate QR code", err);
        });
    }
  }, [type]);

  const [reportLocation, setReportLocation] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportSeverity, setReportSeverity] = useState("");
  const [reportPhotoUrl, setReportPhotoUrl] = useState("");
  const [reportDescription, setReportDescription] = useState("");

  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventType, setEventType] = useState(true);
  const [eventDate, setEventDate] = useState("");
  const [eventMaxPeople, setEventMaxPeople] = useState(10);
  const [eventPhotoUrl, setEventPhotoUrl] = useState("");

  const [newRole, setNewRole] = useState(
    userToPromote ? userToPromote.role : ""
  );

  const [couponDescription, setCouponDescription] = useState("");
  const [couponPoints, setCouponPoints] = useState(0);
  const [couponExpirationDate, setCouponExpirationDate] = useState("");

  const [eventPoints, setEventPoints] = useState(0);

  // API call functions
  const updateUser = async () => {
    const updateUserData = {
      id: currentUser.id,
      name: name,
      username: username,
      photoUrl: photoUrl,
      isPublic: isPublic,
    };

    try {
      const response = await usersApi.updateUser(updateUserData);
      //localStorage.setItem("currUser", JSON.stringify(response.data));
      console.log("Update successful:", response.data);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to update user:", error);
    }
  };

  const changeUserPwd = async () => {
    const changePasswordData = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    console.log("newPassword input:" + newPassword)
    console.log("oldPassword input:" + oldPassword)

    if (newPassword == "") {
      setErrorMessage("Please enter your new password!");
    } else if (newPassword === confirmPassword) {
      try {
        const response = await usersApi.changeUserPassword(changePasswordData);
        console.log("Password changed with success:", response.data);
        authApi.logout();
        window.location.reload();
        onClose();
      } catch (error) {
        setErrorMessage(error.response.data.error);
        console.error("Failed to change password:", error);
      }
    }
    setErrorMessage("Passwords do not match!");
  };

  const createCommunity = async () => {
    const createCommunityData = {
      name: communityName,
      description: communityDescription,
      photoUrl: communityPhotoUrl,
      isPublic: communityIsPublic,
    };

    try {
      const response = await communitiesApi.createCommunity(
        createCommunityData
      );
      console.log("Community created:", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to create community:", error);
    }
  };

  const editCommunity = async () => {
    const updateCommunityData = {
      id: currentCommunity.id,
      name: communityName,
      photoUrl: communityPhotoUrl,
      description: communityDescription,
      isPublic: communityIsPublic,
    };

    try {
      const response = await communitiesApi.updateCommunity(
        updateCommunityData
      );
      console.log("Community updated:", response.data);
      localStorage.setItem("selectedCommunity", JSON.stringify(response.data));
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to update community:", error);
    }
  };

  const createReport = async () => {
    const createReportData = {
      location: reportLocation,
      type: reportType,
      severity: reportSeverity,
      url: reportPhotoUrl,
      description: reportDescription,
    };

    try {
      const response = await reportsApi.createReport(createReportData);
      console.log("Report created:", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to create report:", error);
    }
  };

  const createEvent = async () => {
    const createEventData = {
      title: eventTitle,
      description: eventDescription,
      location: eventLocation,
      type: eventType,
      date: eventDate,
      photoUrl: eventPhotoUrl,
      maxpeople: eventMaxPeople,
      id: currentCommunity.id,
    };

    try {
      const response = await eventsApi.createEvent(createEventData);
      console.log("Eventcreated:", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to create event:", error);
    }
  };

  const promoteUser = async () => {
    try {
      const response = await usersApi.changeRole(userToPromote.id, newRole);
      console.log("Changed Role", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to change role:", error);
    }
  };

  const addCoupon = async () => {
    try {
      const createCouponData = {
        UserId: currentUser.id,
        description: couponDescription,
        points: couponPoints,
        expirationDate: couponExpirationDate,
        photoUrl: photoUrl
      };

      const response = await couponsApi.createCoupon(createCouponData);
      console.log("Created Coupon", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to create coupon:", error);
    }
  };

  function convertKGtoPoints(kg) {
    return Math.round(kg * 40);
  }

  function convertTreestoPoints(n) {
    return Math.round(n * 100);
  }

  const finishEvent = async () => {
    try {
      let resultPoints = 0;
      if (currentEvent.type) {
        resultPoints = convertKGtoPoints(eventPoints);
      } else {
        resultPoints = convertTreestoPoints(eventPoints);
      }

      const response = await eventsApi.finishEvent(
        resultPoints,
        currentEvent.id
      );
      console.log("Event finished", response);
      onClose();
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.error("Failed to finish event:", error);
    }
  };

  const [mfaCode, setMfaCode] = useState("");
  const [errorMfa, setErrorMFA] = useState(false);

  const enableMFA = async () => {
    try {
      const response = await authApi.verifyMFA(mfaCode);
      console.log("MFA activated", response);
      setErrorMFA(false);
      onClose();
    } catch (error) {
      setErrorMFA(true);
      setMfaCode("");
      console.error("Error activating MFA:", error);
    }
  };

  const disableMFA = async () => {
    try {
      const response = await authApi.disableMFA(mfaCode);
      console.log("MFA disabled", response);
      setErrorMFA(false);
      onClose();
    } catch (error) {
      setErrorMFA(true);
      setMfaCode("");
      console.error("Error disabling MFA:", error);
    }
  };

  // Handle Save
  const handleSave = async () => {
    switch (type) {
      case "editUser":
        await updateUser();
        break;
      case "changePassword":
        await changeUserPwd();
        break;
      case "addCommunity":
        await createCommunity();
        break;
      case "editCommunity":
        await editCommunity();
        break;
      case "addReport":
        await createReport();
        break;
      case "addEvent":
        createEvent();
        break;
      case "promoteUser":
        promoteUser();
        break;
      case "addCoupon":
        addCoupon();
        break;
      case "finishEvent":
        finishEvent();
        break;
      case "enableMFA":
        enableMFA();
        break;
      case "disableMFA":
        disableMFA();
        break;
      default:
        console.error("Invalid type");
    }
  };

  const [uploadingImage, setUploadingImage] = useState(false);

  function uploadImage(file) {
    setUploadingImage(true);
    images.upload(file).then((url) => {
      setPhotoUrl(url);
      setCommunityPhotoUrl(url);
      setReportPhotoUrl(url);
      setEventPhotoUrl(url);
      setUploadingImage(false);
    });
  }

  function parseRole(role){
    switch(role){
      case "SYSADMIN":
        return "System Admin";
      case "GS":
        return "System Manager";
      case "GBO":
          return "Backoffice Manager";
      case "GC": 
          return "Community Manager";
      case "EP":
          return "External Partner";
      case "IP":
          return "Internal Partner";
      case "USER":
          return "User";
      
  }
}

  function getPossibleRolesForPromotion() {
    if (currentUser.role == "SYSADMIN") {
      return ["GS", "GBO", "GC", "USER"];
    }

    if (currentUser.role == "GS") {
      return ["GBO", "GC", "USER"];
    }

    return [];
  }

  function getPossibleRolesForCommunityPromotion() {
    return ["ADMIN", "USER"];
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>
          {type === "user"
            ? "Edit Account"
            : type === "editCommunity"
            ? "Edit Community"
            : type === "addCommunity"
            ? "Add Community"
            : type === "addReport"
            ? "Add Report"
            : type === "addEvent"
            ? "Add Event"
            : type === "promoteUser"
            ? "Promote User"
            : type === "promoteCommunityUser"
            ? "Promote Community User"
            : type === "editUser"
            ? "Edit User"
            : type === "changePassword"
            ? "Change Password"
            : type === "addCoupon"
            ? "Add Coupon"
            : type === "finishEvent"
            ? "Finish Event"
            : type === "disableMFA"
            ? "Disable 2FA"
            : type === "enableMFA"
            ? "Enable 2FA"
            : "Invalid Type"}
        </h2>
        <div className="edit-form">
          {type === "editUser" && (
            <>
              <div className="form-row">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="photo">Photo:</label>
                {uploadingImage ? (
                  "Uploading..."
                ) : (
                  <>
                    <div className="popup-upload">
                      {photoUrl.length == 0 ? (
                        <div className="add-photo">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                      ) : (
                        <img src={photoUrl} className="popup-photo" />
                      )}

                      <input
                        type="file"
                        id="photo"
                        accept=".jpg,.jpeg,.png,.gif,.webp,.heic"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />

                      <button
                        onClick={() => {
                          document.getElementById("photo").click();
                        }}
                      >
                        Select Image <br></br>From Computer
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="form-row">
                <label htmlFor="public">Visibility:</label>
                <button
                  className={!isPublic ? "popup-selected" : ""}
                  onClick={() => {
                    setIsPublic(true);
                  }}
                >
                  Public
                </button>
                <button
                  className={isPublic ? "popup-selected" : ""}
                  onClick={() => {
                    setIsPublic(false);
                  }}
                >
                  Private
                </button>
                {/* <input
                  type="text"
                  id="accountPublic"
                  value={isPublic}
                  onChange={(e) => setIsPublic(e.target.value)}
                /> */}
              </div>
            </>
          )}
          {type === "changePassword" && (
            <>
              <div className="form-row">
                <label htmlFor="oldPassword">Current Password:</label>
                <input
                  type="password"
                  id="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="newPassword">New Password:</label>
                <input
                  type="password"
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="confirmPassword">Confirm Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </>
          )}
          {(type === "editCommunity" || type === "addCommunity") && (
            <>
              <div className="form-row">
                <label htmlFor="communityName">Name:</label>
                <input
                  type="text"
                  id="communityName"
                  value={communityName}
                  required
                  onChange={(e) => setCommunityName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="communityDescription">Description:</label>
                <input
                  type="text"
                  id="communityDescription"
                  value={communityDescription}
                  onChange={(e) => setCommunityDescription(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="communityPhotoUrl">PhotoUrl:</label>

                {uploadingImage ? (
                  "Uploading..."
                ) : (
                  <>
                    <div className="popup-upload">
                      {communityPhotoUrl.length == 0 ? (
                        <div className="add-photo">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                      ) : (
                        <img src={communityPhotoUrl} className="popup-photo" />
                      )}

                      <input
                        type="file"
                        id="photo"
                        accept=".jpg,.jpeg,.png,.gif,.webp,.heic"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />

                      <button
                        onClick={() => {
                          document.getElementById("photo").click();
                        }}
                      >
                        Select Image <br></br>From Computer
                      </button>
                    </div>
                  </>
                )}

                {/* <input
                  type="text"
                  id="communityPhotoUrl"
                  value={communityPhotoUrl}
                  onChange={(e) => setCommunityPhotoUrl(e.target.value)}
                /> */}
              </div>
              <div className="form-row">
                <label htmlFor="communityIsPublic">Visibility:</label>
                <button
                  className={!communityIsPublic ? "popup-selected" : ""}
                  onClick={() => {
                    setCommunityIsPublic(true);
                  }}
                >
                  Public
                </button>
                <button
                  className={communityIsPublic ? "popup-selected" : ""}
                  onClick={() => {
                    setCommunityIsPublic(false);
                  }}
                >
                  Private
                </button>
                {/* <input
                  type="text"
                  id="communityIsPublic"
                  value={communityIsPublic}
                  onChange={(e) => setCommunityIsPublic(e.target.value)}
                /> */}
              </div>
            </>
          )}
          {type === "addReport" && (
            <>
              <div className="form-row">
                {/* TODO: Aqui a localização devia ser escolhida num mapa */}
                <label htmlFor="reportLocation">Location:</label>
                <input
                  type="text"
                  id="reportLocation"
                  value={reportLocation}
                  onChange={(e) => setReportLocation(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="reportType">Type:</label>
                <input
                  type="text"
                  id="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="reportSeverity">Severity:</label>
                <input
                  type="number"
                  id="reportSeverity"
                  value={reportSeverity}
                  onChange={(e) => setReportSeverity(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="reportPhotoUrl">PhotoUrl:</label>
                {uploadingImage ? (
                  "Uploading..."
                ) : (
                  <>
                    <div className="popup-upload">
                      {reportPhotoUrl.length == 0 ? (
                        <div className="add-photo">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                      ) : (
                        <img src={reportPhotoUrl} className="popup-photo" />
                      )}

                      <input
                        type="file"
                        id="photo"
                        accept=".jpg,.jpeg,.png,.gif,.webp,.heic"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />

                      <button
                        onClick={() => {
                          document.getElementById("photo").click();
                        }}
                      >
                        Select Image <br></br>From Computer
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="form-row">
                <label htmlFor="reportDescription">Description:</label>
                <input
                  type="text"
                  id="reportDescription"
                  value={reportDescription}
                  onChange={(e) => setReportDescription(e.target.value)}
                />
              </div>
            </>
          )}
          {type === "addEvent" && (
            <>
              <div className="form-row">
                <label htmlFor="eventTitle">Title:</label>
                <input
                  type="text"
                  id="eventTitle"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="eventDescription">Description:</label>
                <input
                  type="text"
                  id="eventDescription"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="eventLocation">Location:</label>
                <input
                  type="text"
                  id="eventLocation"
                  value={eventLocation}
                  onChange={(e) => setEventLocation(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="photoEventCreate">Photo:</label>
                {uploadingImage ? (
                  "Uploading..."
                ) : (
                  <>
                    <div className="popup-upload">
                      {eventPhotoUrl.length == 0 ? (
                        <div className="add-photo">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                      ) : (
                        <img src={eventPhotoUrl} className="popup-photo" />
                      )}

                      <input
                        type="file"
                        id="photo"
                        accept=".jpg,.jpeg,.png,.gif,.webp,.heic"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />

                      <button
                        onClick={() => {
                          document.getElementById("photo").click();
                        }}
                      >
                        Select Image <br></br>From Computer
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="form-row">
                <label htmlFor="eventType">Type:</label>
                <button
                  className={!eventType ? "popup-selected" : ""}
                  onClick={() => {
                    setEventType(true);
                  }}
                >
                  CATCH TRASH
                </button>
                <button
                  className={eventType ? "popup-selected" : ""}
                  onClick={() => {
                    setEventType(false);
                  }}
                >
                  PLANT TREES
                </button>
              </div>
              <div className="form-row">
                <label htmlFor="eventDate">Date:</label>
                <input
                  type="datetime-local"
                  id="eventDate"
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="eventMaxPeople">Max. People</label>
                <input
                  type="number"
                  id="eventMaxPeople"
                  value={eventMaxPeople}
                  onChange={(e) => setEventMaxPeople(e.target.value)}
                />
              </div>
            </>
          )}{" "}
          {type === "promoteUser" && (
            <>
              <div className="form-row">
                <label htmlFor="newRole">New Role:</label>
                <select
                  className="promote-select"
                  value={newRole}
                  onChange={(e) => {
                    setNewRole(e.target.value);
                  }}
                >
                  {getPossibleRolesForPromotion().map((role, index) => (
                    <option key={index} value={role}>
                      {parseRole(role)}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
          {type === "addCoupon" && (
            <>
              <div className="form-row">
                <label htmlFor="couponDescription">Description:</label>
                <input
                  type="text"
                  id="couponDescription"
                  value={couponDescription}
                  onChange={(e) => setCouponDescription(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="couponPoints">Points Required:</label>
                <input
                  type="number"
                  id="couponPoints"
                  value={couponPoints}
                  onChange={(e) => setCouponPoints(e.target.value)}
                />
              </div>
              <div className="form-row">
                <label htmlFor="couponExpirationDate">Expiration Date:</label>
                <input
                  type="datetime-local"
                  id="couponExpirationDate"
                  value={couponExpirationDate}
                  onChange={(e) => setCouponExpirationDate(e.target.value)}
                />
              </div>
              <div className="popup-upload">
                      {photoUrl.length == 0 ? (
                        <div className="add-photo">
                          <i class="fa-solid fa-plus"></i>
                        </div>
                      ) : (
                        <img src={photoUrl} className="popup-photo" />
                      )}

                      <input
                        type="file"
                        id="photo"
                        accept=".jpg,.jpeg,.png,.gif,.webp,.heic"
                        onChange={(e) => uploadImage(e.target.files[0])}
                      />

                      <button
                        onClick={() => {
                          document.getElementById("photo").click();
                        }}
                      >
                        Select Image <br></br>From Computer
                      </button>
                    </div>
            </>
          )}
          {type === "finishEvent" && (
            <>
              <div className="form-row">
                <label htmlFor="eventPoints">
                  {currentEvent.type
                    ? "Collected Trash (KG):"
                    : "Trees Planted:"}
                </label>
                <input
                  type="number"
                  id="eventPoints"
                  value={eventPoints}
                  onChange={(e) => setEventPoints(e.target.value)}
                />
              </div>
            </>
          )}
          {type === "enableMFA" && (
            <>
              {mfaStep === 0 ? (
                <div className="form-column">
                  {loadingQR ? <Loader /> : <img src={qrCode} />}
                  <p>
                    Scan the QR code with your 2FA app (Google Authenticator,
                    Microsoft Authenticator...)
                  </p>
                </div>
              ) : mfaStep === 1 ? (
                <div className="form-column">
                  {errorMfa ? (
                    <p className="mfa-error">Invalid code provided...</p>
                  ) : (
                    <></>
                  )}
                  <input
                    type="number"
                    className="mfa-code"
                    value={mfaCode}
                    onChange={(e) => {
                      setMfaCode(e.target.value);
                    }}
                  />
                  <p>Enter the code that is visible on the Authenticator App</p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {type === "disableMFA" && (
            <div className="form-column">
              {errorMfa ? (
                <p className="mfa-error">Invalid code provided...</p>
              ) : (
                <></>
              )}
              <input
                type="number"
                className="mfa-code"
                value={mfaCode}
                onChange={(e) => {
                  setMfaCode(e.target.value);
                }}
              />
              <p>Enter the code that is visible on the Authenticator App</p>
            </div>
          )}
        </div>

        <div className="errormsg">
          <p>{errorMessage}</p>
        </div>

        <div className="popup-buttons">
          <button className="deleteBtn" onClick={onClose}>
            Cancel
          </button>

          {type === "enableMFA" && mfaStep == 0 ? (
            <button
              onClick={() => {
                setMfaStep(mfaStep + 1);
              }}
            >
              Next Step
            </button>
          ) : (
            <>
              <button onClick={handleSave}>
                {type === ("addCommunity" || "addReport")
                  ? "Create"
                  : type === "enableMFA"
                  ? "Complete Activation"
                  : type === "disableMFA"
                  ? "Confirm deactivation"
                  : "Save"}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PopUp;
