import React, { useState, useEffect } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { Marker, AdvancedMarker } from "@vis.gl/react-google-maps";
import "./reportsMap.css";
import customMarkerIcon from "../../../img/ecopontoMarker.png";
import { Reports } from "../../../lib/api";
import UserIcon from "../../../img/userIcon.png";
import ResidueIcon from "../../../img/reportMarkerResidues.png";
import PollutionIcon from "../../../img/reportMarkerPollution.png";
import WasteIcon from "../../../img/reportMarkerWaste.png";
import OtherIcon from "../../../img/reportMarkerOther.png";

import Ecopontos from "../../../data/ecopontosShort.json";

const MAPS_API_KEY = "AIzaSyAiSN-g9bD9GN0Ior43A1xzOhV4YNc3ZgU";

const ecopontosLocations = Ecopontos.locations;
const reportApi = new Reports();

const mapStyles = [
  {
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#3bce8e",
      },
      {
        lightness: 70,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: 90,
      },
    ],
  },
];

function ReportsMap({ reportsData, homePage }) {
  const [userLocation, setUserLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [userReportsLocation, setUserReportsLocation] = useState([]);
  const [partnersLocation, setPartnersLocation] = useState([]);

  useEffect(() => {
    getLocation();
    if (!homePage) {
      getToClean();
    }
  }, []);

  useEffect(() => {
    processReportLocation();
  }, [reportsData]);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setUserLocation(pos.coords);
        setLoading(false);
      });
    } else {
      console.log("Geolocation failed");
    }
  }

  function getToClean() {
    setLoading(true);
    reportApi.getReportsToClean().then((response) => {
      const markers = response.data.map((report, index) => {
        const loc = report.location.split(",");
        return {
          id: index,
          position: { lat: parseFloat(loc[0]), lng: parseFloat(loc[1]) },
        };
      });
      setMarkers(markers);
      setLoading(false);
    });
  }

  function processReportLocation() {
    if (reportsData && reportsData.length > 0) {
      const coord = reportsData.map((marker) => {
        const [latitude, longitude] = marker.location.split(",");
        return [latitude, longitude, marker.type];
      });
      setUserReportsLocation(coord);
    }
  }


  // Function to calculate distance between two coordinates in meters using Haversine formula
  const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth radius in meters
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon1 - lon2) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  };

  return (
    <>
      {loading || !userLocation ? (
        <div className="loading-screen">
          <i className="fa fa-spinner fa-spin"></i>
          <p>Loading</p>
        </div>
      ) : (
        <APIProvider apiKey={MAPS_API_KEY}>
          {homePage ? (
            <div className="homepage-map-container">
              <div className="homepage-map">
                <h1>Bin Locator</h1>
                <Map
                  id="map"
                  siz
                  defaultCenter={{
                    lat: userLocation.latitude,
                    lng: userLocation.longitude,
                  }}
                  defaultZoom={16}
                  gestureHandling={"cooperative"}
                  disableDefaultUI={false}
                  styles={mapStyles}
                  clickableIcons={true}
                >
                  <Marker
                    clickable={true}
                    position={{
                      lat: userLocation.latitude,
                      lng: userLocation.longitude,
                    }}
                    icon={{
                      url: UserIcon,
                      scaledSize: {
                        width: 60,
                        height: 60,
                      },
                    }}
                  />

                  {ecopontosLocations
                    .filter((marker) => {
                      const distance = getDistance(
                        userLocation.latitude,
                        userLocation.longitude,
                        parseFloat(marker.latitude),
                        parseFloat(marker.longitude)
                      );
                      return distance <= 1000; // Filter markers within 1km
                    })
                    .map((marker) => (
                      <Marker
                        key={marker.id}
                        position={{
                          lat: parseFloat(marker.latitude),
                          lng: parseFloat(marker.longitude),
                        }}
                        icon={{
                          url: customMarkerIcon,
                          scaledSize: {
                            width: 40,
                            height: 40,
                          },
                        }}
                      />
                    ))}
                </Map>
              </div>
            </div>
          ) : (
            <div className="map-container">
              <Map
                defaultCenter={{
                  lat: userLocation.latitude,
                  lng: userLocation.longitude,
                }}
                defaultZoom={16}
                gestureHandling={"cooperative"}
                disableDefaultUI={false}
                styles={mapStyles}
                clickableIcons={true}
              >
                <Marker
                  clickable={true}
                  position={{
                    lat: userLocation.latitude,
                    lng: userLocation.longitude,
                  }}
                  icon={{
                    url: UserIcon,
                    scaledSize: {
                      width: 60,
                      height: 60,
                    },
                  }}
                />

                {ecopontosLocations
                  .filter((marker) => {
                    const distance = getDistance(
                      userLocation.latitude,
                      userLocation.longitude,
                      parseFloat(marker.latitude),
                      parseFloat(marker.longitude)
                    );
                    return distance <= 1000; // Filter markers within 1km
                  })
                  .map((marker) => (
                    <Marker
                      key={marker.id}
                      position={{
                        lat: parseFloat(marker.latitude),
                        lng: parseFloat(marker.longitude),
                      }}
                      icon={{
                        url: customMarkerIcon,
                        scaledSize: {
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  ))}
                {userReportsLocation
                  .filter((marker) => {
                    const distance = getDistance(
                      userLocation.latitude,
                      userLocation.longitude,
                      parseFloat(marker[0]),
                      parseFloat(marker[1])
                    );
                    return distance <= 1000; // Filter user reports within 1km
                  })
                  .map(
                    (marker, index) => (
                      console.log(marker),
                      (
                        <Marker
                          key={index}
                          position={{
                            lat: parseFloat(marker[0]),
                            lng: parseFloat(marker[1]),
                          }}
                          icon={{
                            url:
                              marker[2] == "pollution"
                                ? PollutionIcon
                                : "residue"
                                ? ResidueIcon
                                : "waste"
                                ? WasteIcon
                                : OtherIcon,
                            scaledSize: {
                              width: 50,
                              height: 50,
                            },
                          }}
                        />
                      )
                    )
                  )}
              </Map>
            </div>
          )}
        </APIProvider>
      )}
    </>
  );
}

export default ReportsMap;
