import React, { useState, useEffect, useContext } from "react";
import "./selectedCommunity.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Communities, Coupons, Users } from "../../../lib/api.js";
import { AuthContext } from "../../../App.js";
import PopUp from "./PopUp.jsx";

function SelectedPartner() {
  const couponsApi = new Coupons();
  const usersApi = new Coupons();
  const location = useLocation();
  var { selectedPartner } = location.state;

  console.log(selectedPartner);

  const navigate = useNavigate();

  const currentAuth = useContext(AuthContext);
  var currUser = currentAuth.user;

  const [partnerCoupons, setPartnersCoupons] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupError, setPopupError] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentAuth && currentAuth.user) {
      setPartnersCoupons(selectedPartner.coupons);
      setLoading(false);
    }
  }, [currentAuth]);

  const [popupType, setPopupType] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleEditClick = (type) => {
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };


  async function deleteCoupon(couponId) {
    setLoading(true);
    try {
      const response = await couponsApi.deleteCoupon(couponId);
      console.log("Coupon deleted: ", response);
    } catch (error) {
      console.error("Failed to delete coupon:", error);
    }
    finally {
      setLoading(false);
    }
  }

  async function getPartnerCoupons() {
    setLoading(true);
    try {
      const response = await usersApi.getPartner(selectedPartner.id);
      setPartnersCoupons(response.data.coupons);
    } catch (error) {
      console.error("Failed to get partner coupons:", error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className="selected-community-list">
      <div className="selected-community-container">
        <div className="selected-community-page">
          <div className="backBtn-div">
            <button
              id="backBtn"
              onClick={() => navigate("/system-management/partners")}
              className="back-button"
            >
              <i className="fa-solid fa-arrow-left-long"></i>
              Back
            </button>
          </div>
          <div className="selected-community-top-bar">
            <div className="selected-community-profile">
              <div className="selected-community-image">
                <img src={selectedPartner.photoUrl} alt="Community" />
              </div>
              <div className="selected-community-info">
                <h1>{selectedPartner.name}</h1>
                <p></p>
              </div>
            </div>

            <div className="search-div">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                className="searchBar"
                type="search"
                placeholder="Search"
              ></input>
               <button className="refresh" onClick={()=>{getPartnerCoupons()}}>
                <i class="fa-solid fa-refresh"></i>
              </button>
            </div>
          
          </div>

          <div className="selected-community-table">
            <table className="statsTable">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Cost</th>
                  <th>Expiration Date</th>
                  <th>Photo</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                {partnerCoupons.map((coupon, index) => (
                  <tr key={index}>
                    <td className="td-name">{coupon.description}</td>
                    <td>{coupon.points} points</td>
                    <td>{coupon.expirationDate.split("T")[0]}</td>
                    <td>
                      <img className="partner-image" src={coupon.photoUrl} />
                    </td>
                    <td>
                      <div
                        className="actions-btns"
                        style={{ display: "flex", placeContent: "flex-start" }}
                      >
                        <button className="removeBtn" onClick={async ()=>{
                          deleteCoupon(coupon.couponId);
                          getPartnerCoupons();
                        }}>
                        <i class="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className={'pop-up ${popupError ? "error" : ""}'}>
          {popupMessage}
        </div>
      )}
      {isPopupVisible && (
        <PopUp
          type={popupType}
          currentUser={currUser}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}

export default SelectedPartner;