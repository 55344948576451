import React from "react";
import "./communities.css";
import { Reports } from "../../../lib/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PopUp from "./PopUp";

function ReportsPage( {currentUser} ) {
  const reportsApi = new Reports();
  const navigate = useNavigate();

  const [reports, setReports] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getReportsToClean() {
    setLoading(true);
    try {
      const reports = await reportsApi.getAllReports();
      setReports(reports.data);
    } catch (error) {
      console.error("Failed to get reports:", error);
    }
    finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    getReportsToClean();
  }, []);

  const handleEditClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    getReportsToClean();
  };

  function openLocation(location){
    window.open(`https://www.google.com/maps/search/?api=1&query=${location}`);
  }



  return (
    <>{loading ? 
      <div className="loading-screen">
      <i className="fa fa-spinner fa-spin"></i> 
      <p>Loading</p>
    </div>
   : <>   
    <div className="communities-list">
      <div className="communities-list-container" style={{overflowY:"scroll"}}> 
        <div className="communities-page-container"  >
          <div className="community-options">
            <h1>Reports</h1>
            <div className="search-div">
           
              {/* <i className="fa-solid fa-magnifying-glass"></i>
              <input
                className="searchBar"
                type="search"
                placeholder="Search"
              ></input> */}
               <button className="refresh" onClick={()=>{getReportsToClean()}}>
                <i class="fa-solid fa-refresh"></i>
              </button>
              <button className="addUserBtn" onClick={handleEditClick}>
                <i class="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>

          
          <div className="communities-table" >
            <table className="statsTable" >
              <thead>
                <tr>
                  <th>
                    <button className="sortBtn">
                      Author{" "}
                      <i className="fa-solid fa-arrow-down-wide-short"></i>
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      Description
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      Location
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      Severity
                      <i className="fa-solid fa-arrow-down-wide-short"></i>
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      Type
                      <i className="fa-solid fa-arrow-down-wide-short"></i>
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      State
                      <i className="fa-solid fa-arrow-down-wide-short"></i>
                    </button>
                  </th>
                  <th>
                    <button className="sortBtn">
                      Image
                    </button>
                  </th>
                  <th>
                    <span className="sortBtn">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports.map((report, index) => (
                  <tr key={index}>
                    <td className="td-name">{report.userName}</td>
                    <td>{report.description}</td>
                    <td><a onClick={()=>{openLocation(report.location)}}>Open Maps</a></td>
                    <td>{report.severity}</td>
                    <td>{report.type}</td>
                    <td>{report.state}</td>
                    <td><img className="report-image" src={report.url}/></td>
                    <td>
                        <div className="actions-btns">
                          <button onClick={async ()=>{
                            await reportsApi.cleanReport(report.id);
                            getReportsToClean();
                          }}
                            className="doneBtn"
                          >
                            <i class="fa-solid fa-check"></i>
                          </button>
                          <button onClick={async ()=>{
                            await reportsApi.deleteReport(report.id);
                            getReportsToClean();
                          }}
                            className="removeBtn"
                          >
                            <i class="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className="pagination">
              <button className="previous-page-btn">
                <i className="fa-solid fa-caret-left"></i> Previous
              </button>
              <p>Page 1 of 2</p>
              <button className="next-page-btn">
                Next <i className="fa-solid fa-caret-right"></i>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <PopUp type={"addReport"} currentUser={currentUser} onClose={handleClosePopup} />
      )}
    </div>
    </>
    }
    </>
  );
}

export default ReportsPage;
