import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoadingPage from "./Pages/LoadingPage";
import { Suspense, createContext, lazy, useEffect, useState } from "react";
import RequireLogoutRoute from "./views/RequireLogoutRoute";
import RequireLoginRoute from "./views/RequireLoginRoute";

import {decodeToken,isExpired } from "react-jwt";
import { Users } from "./lib/api";
import SystemManagement from "./Pages/SystemManagement/SystemManagement";
import SelectedCommunity from "./Pages/SystemManagement/Components/SelectedCommunity";
import SelectedPartner from "./Pages/SystemManagement/Components/SelectedPartner";

const LoginPage = lazy(() => import("./Pages/LoginPage"));
const RegisterPage = lazy(() => import("./Pages/RegisterPage"));
const VerifyEmail = lazy(() => import("./Pages/VerifyEmail"));
// const LoginPage = lazy(() => import("./Pages/LoginPage"));

const CURR_USER = "currUser";

const AuthContext = createContext(null);
const users = new Users();

const App = () => {

  const {pathname} = useLocation();
  const [currentAuth, setCurrentAuth] = useState({user:null,token:null});
  const [loading, setLoading] = useState(true);

  function saveCurrentUser(currUser) {
    localStorage.setItem(CURR_USER, currUser);
  }

  function rfsUser(token){
    users.getCurrentUser().then((res) => {
      setCurrentAuth({user:res.data,token:token,refreshUser:rfsUser});
      saveCurrentUser(JSON.stringify(res.data));
    });
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (token) {

      setCurrentAuth({...currentAuth,token:token,isExpired:isExpired(token),decodeToken:decodeToken(token)});
      
      users.getCurrentUser().then((res) => {
        setCurrentAuth({user:res.data,token:token,refreshUser:rfsUser(token)});
        saveCurrentUser(JSON.stringify(res.data));        
      }
      ).catch((err) => {
        if(err.response.status == 404){
          localStorage.clear();
          window.location.reload();
        }
        console.log(err);
      });

    }
    setLoading(false);
  },[pathname]);
  

  return (
    <>
     {loading ? <LoadingPage/> : 
     <Suspense fallback={<LoadingPage/>} >
      <AuthContext.Provider value={currentAuth}>
    
          <Routes>
              <Route path="register">
                <Route index element={<RegisterPage/>} />
                <Route path="verify" element={<VerifyEmail/>} />
              </Route>
              <Route path="login" element={<RequireLogoutRoute><LoginPage/></RequireLogoutRoute>} />
              <Route path="system-management" element={<RequireLoginRoute><SystemManagement/></RequireLoginRoute>}>
                <Route path="*" element={<RequireLoginRoute><SystemManagement/></RequireLoginRoute>}/>
              </Route>
              <Route path="selected-community" element={<RequireLoginRoute><SelectedCommunity/></RequireLoginRoute>}/>
              <Route path="selected-partner" element={<RequireLoginRoute><SelectedPartner/></RequireLoginRoute>}/>
              <Route path="*" element={<Navigate to="/login" />}></Route>
          </Routes>

      </AuthContext.Provider>
      </Suspense>
    }
    </>
  );
};

export {App, AuthContext};
