
import CubeLoader from '../../views/CubeLoader';
import './LoadingPage.css'

import Transitions from '../Transitions'

function LoadingPage (props) {


    return (<Transitions>
    
    <div className="loading-page">
        <CubeLoader/>
        <h4 className='loading-page-text'>{props.text ? props.text : "A carregar..."}</h4>
    </div>
    
    </Transitions>);
    
}

export default LoadingPage