import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const Graph = ({ data, type }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  let parsedData = [];
  try {
    parsedData = JSON.parse(data);
    console.log(parsedData);
  } catch (error) {
    console.error("Failed to parse data:", error);
  }

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    if (type == "reports") {
      const reportCounts = {};
      parsedData.forEach((item) => {
        if (reportCounts[item.type]) {
          reportCounts[item.type]++;
        } else {
          reportCounts[item.type] = 1;
        }
      });

      const labels = Object.keys(reportCounts);
      const dataPoints = Object.values(reportCounts);
      const maxCount = Math.max(...dataPoints);

      chartInstanceRef.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Number of Reports",
              data: dataPoints,
              backgroundColor: "rgba(59, 206, 142, 0.5)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Number of Reports",
              },
              max: maxCount + 1,
              grid: {
                circular: true,
              },
            },
            x: {
              title: {
                display: true,
                text: "Report Types",
              },
            },
          },
        },
      });
    } else if (type == "users") {
      const userCounts = {};
      parsedData.forEach((item) => {
        if (userCounts[item.role]) {
          userCounts[item.role]++;
        } else {
          userCounts[item.role] = 1;
        }
      });

      const userLabels = Object.keys(userCounts);
      console.log("userLabels ", userLabels);
      console.log("userCounts ", userCounts);
      const userDataPoints = Object.values(userCounts);
      const usersMaxCount = Math.max(...userDataPoints);

      chartInstanceRef.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: userLabels,
          datasets: [
            {
              label: "Number of Users",
              data: userDataPoints,
              backgroundColor: "rgba(59, 206, 142, 0.5)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Number of Users",
              },
              max: usersMaxCount + 1,
            },
            x: {
              title: {
                display: true,
                text: "User Roles",
              },
            },
          },
        },
      });
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [parsedData]);

  return <><canvas ref={chartRef}></canvas></>;
};

export default Graph;
