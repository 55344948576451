import React from 'react'

import './Loader.css'

const Loader = (props) => {

  return (
    <div style={{transform:"scale(" +(props.scale || 1) + ")"}}className="lds-ring"><div></div><div></div><div></div><div></div></div>
  )
}

export default Loader