import React from "react";
import "./partnersPage.css";
import { Users } from "../../../lib/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PartnersPage() {
  const usersApi = new Users();
  const navigate = useNavigate();

  const [partnerRequests, setPartnerRequests] = useState([]);
  const [loadingPR, setLoadingPR] = useState(false);

  const [partners, setPartners] = useState([]);
  const [loadingP, setLoadingP] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);

  async function getPartnerRequests() {
    try {
      setLoadingPR(true);
      //setLoadingAll(true);
      const res = await usersApi.listPartnerRequests();
      setLoadingPR(false);
      //setLoadingAll(false);
      setPartnerRequests(res.data);
    } catch (error) {
      console.error("Failed to get partner requests:", error);
    }
  }

  async function getSelectedPartner(partnerId) {
    try {
      console.log("entrou aqui");
      setLoadingAll(true);
      const res = await usersApi.getPartner(partnerId);
      setLoadingAll(false);
      openSelectedPartner(res.data);
    } catch (error) {
      console.error("Failed to get partner info:", error);
    }
  }

  async function getPartners() {
    try {
      setLoadingP(true);
      //setLoadingAll(true);
      const res = await usersApi.listPartners();
      setLoadingP(false);
      //setLoadingAll(false);
      setPartners(res.data);
    } catch (error) {
      console.error("Failed to get partner requests:", error);
    }
  }

  async function approveRequest(id) {
    try {
      setLoadingPR(true);
      const res = await usersApi.acceptPartner(id);
      getPartnerRequests();
      getPartners();
    } catch (error) {
      console.error("Failed to get partner requests:", error);
    }
  }

  async function declineRequest(id) {
    try {
      setLoadingPR(true);
      const res = await usersApi.declinePartner(id);
      getPartnerRequests();
      getPartners();
    } catch (error) {
      console.error("Failed to get partner requests:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoadingAll(true);
      await Promise.all([getPartnerRequests(), getPartners()]);
      setLoadingAll(false);
    };
    fetchData();
  }, []);

  function parseRole(role) {
    switch (role) {
      case "SYSADMIN":
        return "System Admin";
      case "GS":
        return "System Manager";
      case "GBO":
        return "Backoffice Manager";
      case "GC":
        return "Community Manager";
      case "EP":
        return "External Partner";
      case "IP":
        return "Internal Partner";
      case "USER":
        return "User";
    }
  }

  function openSelectedPartner(partner) {
    console.log("partner enviado da pag de partners: ", partner);
    navigate("/selected-partner", {
      state: { selectedPartner: partner },
    });
  }

  return (
    <>
      {loadingAll ? (
        <div className="loading-screen">
          <i className="fa fa-spinner fa-spin"></i>
          <p>Loading</p>
        </div>
      ) : (
        <div className="partners-grid-2">
          {loadingPR ? (
            <div className="loading-screen">
              <i className="fa fa-spinner fa-spin"></i>
              <p>Loading</p>
            </div>
          ) : (
            <div className="partners-list">
              <div className="partners-list-container">
                <div className="partners-page-container">
                  <div className="partner-options">
                    <h1>Partners Requests</h1>
                    <button
                      className="refresh"
                      onClick={() => {
                        getPartnerRequests();
                      }}
                    >
                      <i class="fa-solid fa-refresh"></i>
                    </button>
                  </div>
                </div>

                {partnerRequests.length == 0 ? (
                  <h4>No requests to approve...</h4>
                ) : (
                  <div className="partners-table">
                    <table className="statsTable">
                      <thead>
                        <tr>
                          <th>
                            <button className="sortBtn">Name</button>
                          </th>
                          <th>
                            <button className="sortBtn">Role</button>
                          </th>
                          <th>
                            <button className="sortBtn">Place</button>
                          </th>
                          <th>
                            <button className="sortBtn">NIF</button>
                          </th>
                          <th>
                            <button className="sortBtn">Photo</button>
                          </th>
                          <th>
                            <button className="sortBtn">Action</button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {partnerRequests.map((partner, index) => (
                          <tr key={index}>
                            <td className="td-name">{partner.name}</td>
                            <td>{parseRole(partner.role)}</td>
                            <td>
                              <a
                                target="_blank"
                                href={
                                  "https://www.google.com/maps/place/?q=place_id:" +
                                  partner.placeId
                                }
                              >
                                Open Maps
                              </a>
                            </td>
                            <td>{partner.nif}</td>
                            <td>
                              <img
                                className="partner-image"
                                src={partner.photoUrl}
                              />
                            </td>
                            <td className="td-request">
                              <div
                                className="actions-btns"
                                style={{
                                  display: "flex",
                                  placeContent: "flex-start",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    approveRequest(partner.id);
                                  }}
                                >
                                  <i class="fa-solid fa-check"></i>
                                </button>
                                <button
                                  className="removeBtn"
                                  onClick={() => {
                                    declineRequest(partner.id);
                                  }}
                                >
                                  <i class="fa-solid fa-x"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}

          {loadingP ? (
            <div className="loading-screen">
              <i className="fa fa-spinner fa-spin"></i>
              <p>Loading</p>
            </div>
          ) : (
            <div className="partners-list">
              <div className="partners-list-container">
                <div className="partners-page-container">
                  <div className="partner-options">
                    <h1>Approved Partners</h1>

                    <button
                      className="refresh"
                      onClick={() => {
                        getPartners();
                      }}
                    >
                      <i class="fa-solid fa-refresh"></i>
                    </button>
                  </div>
                </div>

                {partners.length == 0 ? (
                  <h4>No approved requests to see...</h4>
                ) : (
                  <div className="partners-table">
                    <table className="statsTable">
                      <thead>
                        <tr>
                          <th>
                            <button className="sortBtn">Name</button>
                          </th>
                          <th>
                            <button className="sortBtn">Role</button>
                          </th>
                          <th>
                            <button
                              className="sortBtn"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Place
                            </button>
                          </th>
                          <th>
                            <button className="sortBtn">NIF</button>
                          </th>
                          <th>
                            <button className="sortBtn">Photo</button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {partners.map((partner, index) => (
                          <tr key={index}>
                            <td
                              className="td-name"
                              onClick={() => {
                                getSelectedPartner(partner.id);
                              }}
                            >
                              {partner.name}
                            </td>
                            <td>{parseRole(partner.role)}</td>
                            <td>
                              <a
                                target="_blank"
                                href={
                                  "https://www.google.com/maps/place/?q=place_id:" +
                                  partner.placeId
                                }
                              >
                                <button>Maps</button>
                              </a>
                            </td>
                            <td>{partner.nif}</td>
                            <td>
                              <img
                                className="partner-image"
                                src={partner.photoUrl}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PartnersPage;
