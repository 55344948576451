import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./accountPage.css";
import PopUp from "./PopUp";
import "./reportsMap.css";
import ReportsMap from "./ReportsMap";
import { AuthContext } from "../../../App";
import { Events, Users } from "../../../lib/api";
import emptyListIcon from "../../../img/noCommunitiesCardIcon.png"

function AccountPage({ currentUser }) {
  const navigate = useNavigate();
  const currentAuth = useContext(AuthContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupType,setPopupType] = useState("editUser");

  const usersApi = new Users();
  const eventsApi = new Events();

  const [currentUserCommunities, setCurrentUserCommunities] = useState([]);
  const [currentUserReports, setCurrentUserReports] = useState([]);
  const [currentUserEvents, setCurrentUserEvents] = useState([]);
  const [loadingUC, setLoadingUC] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);

  async function getUserCommunities() {
    setLoadingUC(true);
    try {
      const response = await usersApi.getUserCommunities(currentAuth.user.id);
      setCurrentUserCommunities(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to get communities:", error);
    } finally {
      setLoadingUC(false);
    }
  }

  async function getUserReports() {
    setLoadingUC(true);
    try {
      const response = await usersApi.getUserReports();
      setCurrentUserReports(response.data);
      //console.log("user reports:", response.data)
      return response.data;
    } catch (error) {
      console.error("Failed to get user reports:", error);
    }
  }

  async function getEvents() {
    setLoadingEvents(true);
    try {
      const reports = await eventsApi.getUserEvents();
      setCurrentUserEvents(reports.data);
    } catch (error) {
      console.error("Failed to get reports:", error);
    } finally {
      setLoadingEvents(false);
      
    }
  }

  useEffect(() => {
    getUserCommunities();
    getUserReports();
    getEvents();
  }, []);

  const handleEditClick = (type) => {
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    window.location.reload();
  };

  function computeLevel(level) {
    return level.toString().split(".")[0];
  }

  function computeLevelPercentage(level) {
    if (!level.toString().includes(".")) return 0;
    return level.toString().split(".")[1] === undefined
      ? 0
      : level.toString().split(".")[1].substring(0, 2);
  }

  function getUserInitials(name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("");
  }

  return (
    <div className="account-page-container">
      <div className="account-top-bar">
        <div className="account-profile">
          <div className="account-image">
            {currentAuth.user.photoUrl &&
            currentAuth.user.photoUrl.length > 0 ? (
              <img
                src={currentAuth.user.photoUrl}
                alt={`${currentAuth.user.name}'s profile`}
              />
            ) : (
              <div className="first-letter">
                <h2>{getUserInitials(currentAuth.user.name)}</h2>
              </div>
            )}
          </div>

          <div className="account-info">
            <h1>{currentAuth.user.name}</h1>
            <h2>@{currentAuth.user.username}</h2>
          </div>
        </div>
        {currentAuth.user && (
          <div className="user-level">
            Points {currentAuth.user.points}
            <div className="progress-bar">
              Level {computeLevel(currentAuth.user.level)}
              &nbsp; &nbsp;
              <progress
                value={computeLevelPercentage(currentAuth.user.level)}
                max="100"
              />
            </div>
          </div>
        )}

        <div className="edit-account">
          <div className="account-editBtns">
            <div className="editBtns-row">
              <button className="editBtn" onClick={()=>{handleEditClick("editUser")}}>
              Edit
            </button>
            <button className="editBtn" onClick={()=>{handleEditClick("changePassword")}}>
              Change Password
            </button>
            </div>
            
            <div className="editBtns-row">
              {currentAuth.user.enableMFA ?  
            <button className="mfaBtn-on" onClick={()=>{handleEditClick("disableMFA")}}>
               Deactivate 2FA
            </button> :  
            <button className="mfaBtn" onClick={()=>{handleEditClick("enableMFA")}}>
               Activate 2FA
            </button>}           
            <button className="deleteBtn">Delete</button>
            </div>
            
          </div>
        </div>
      </div>

      <div className="user-info-grid">
        <div className="user-info-box" id="communities-box">
          <h1>Communities</h1>
          <div className="user-communities">
            {loadingUC ? (
              <div className="loading-screen">
                <i className="fa fa-spinner fa-spin"></i>
                <p>Loading</p>
              </div>
            ) : (
              <ul className="ul-communities">
                {currentUserCommunities.length != 0 ? (
                  currentUserCommunities.map((community, index) => (
                    <li
                      key={index}
                      className="community-info"
                      onClick={() =>
                        navigate("/selected-community", {
                          state: {
                            selectedCommunity: community,
                          },
                        })
                      }
                    >
                      <div className="cm-flex">
                        <div className="community-image">
                          <img src={community.photoUrl} />
                        </div>
                        <h4>{community.name} </h4>
                      </div>
                      <b> {community.participants}</b>
                    </li>
                  ))
                ) : (
                  <div className="empty-list">
                    <p>No Communities joined</p>
                  </div>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="user-info-box" id="events-box">
          <h1>Events</h1>
          <div className="user-communities">
            {loadingEvents ? (
              <div className="loading-screen">
                <i className="fa fa-spinner fa-spin"></i>
                <p>Loading</p>
              </div>
            ) : (
              <ul className="ul-communities">
                {currentUserEvents.length != 0 ? (
                  currentUserEvents.map((event, index) => (
                    <li key={index} className="community-info">
                      <div className="cm-flex">
                      <div className="community-image">
                        <img src={event.photoUrl} alt={event.title} />
                      </div>
                      <h4>{event.title} </h4>
                      </div>

                      <b> {event.participants} / {event.maxpeople} </b>
                    </li>
        
                  ))
                ) : (
                  <div className="empty-list">
                    <p>No events joined</p>
                  </div>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="user-info-box" id="map-box">
          <h1>My Reports</h1>
          <div className="small-map-container">
            <ReportsMap reportsData={currentUserReports}/>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <PopUp
          type={popupType}
          currentUser={currentAuth.user}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}

export default AccountPage;
