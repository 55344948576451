import React, { useState, useEffect, useContext } from "react";
import "./userPage.css";
import { Users } from "../../../lib/api";
import { AuthContext } from "../../../App";
import PopUp from "./PopUp";

function UsersPage() {
  const usersApi = new Users();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupError, setPopupError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [popupType, setPopupType] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [userToPromote, setUserToPromote] = useState(null);

  const usersPerPage = 8;

  const currentAuth = useContext(AuthContext);

  async function getAllUsersWithPagination(newPage) {
    setLoading(true);
    try {
      const response = await usersApi.getAllUsersWithPagination(
        newPage,
        usersPerPage
      );
      const { users: newUsers, currentPage: cPage, totalPages: tPages } = response.data;
      setUsers(newUsers);
      setCurrentPage(cPage);
      setTotalPages(tPages);
    } catch (error) {
      console.error("Failed to get users:", error);
    } finally {
      setLoading(false);
    }
  }


  function search(text){
  
   usersApi.searchByName(text).then((response) => {
      setUsers(response.data);
      setCurrentPage(1);
      setTotalPages(1);
    }
  )
  }


  useEffect(() => {
    getAllUsersWithPagination(currentPage);
  }, []);


  function nextPage() {
    getAllUsersWithPagination(currentPage + 1);

  }

  function prevPage() {

    getAllUsersWithPagination(currentPage - 1);

  }
  

  const blockUser = async (userId, block) => {
    try {
      await usersApi.blockUser(userId, block);
      setPopupMessage("User blocked successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3500);
      getAllUsersWithPagination(true);
    } catch (error) {
      setPopupMessage(error.response.data.error);
      setShowPopup(true);
      setPopupError(true);
      setTimeout(() => setShowPopup(false), 3500);
    }
  };

  const deleteUser = async (userId) => {
    try {
      await usersApi.deleteUser(userId);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      setPopupMessage("User deleted successfully");
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3500);
      getAllUsersWithPagination(true);
    } catch (error) {
      setPopupMessage(error.response.data.error);
      setShowPopup(true);
      setPopupError(true);
      setTimeout(() => setShowPopup(false), 3500);
    }
  };


  function computeLevel(level){
    return level.toString().split(".")[0];
  }

  function computeLevelPercentage(level) {
    
    return level.toString().split(".")[1] === undefined ? 0 : level.toString().split(".")[1].substring(0, 2);
  }

  const handleEditClick = (type) => {
    setPopupType(type);
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    getAllUsersWithPagination(currentPage);

  };

  function parseRole(role){
    switch(role){
      case "SYSADMIN":
        return "System Admin";
      case "GS":
        return "System Manager";
      case "GBO":
          return "Backoffice Manager";
      case "GC": 
          return "Community Manager";
      case "EP":
          return "External Partner";
      case "IP":
          return "Internal Partner";
      case "USER":
          return "User";
      
  }
}


  return (
    <>{loading ? 
      <div className="loading-screen">
      <i className="fa fa-spinner fa-spin"></i> 
      <p>Loading</p>
    </div>
   : <>   
    <div className="users-list">
      {showPopup && (
        <div className={`pop-up ${popupError ? "error" : ""}`}>
          {popupMessage}
        </div>
      )}
      <div className="users-list-container">
        <div className="users-page-container">
          <div className="user-options">
            <h1>Users</h1>
            <div className="search-div">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                id = "search"
                className="searchBar"
                type="search"
                placeholder="Search"
                onChange={(e)=>{if(e.target.value === "") getAllUsersWithPagination(1);}}
                onKeyDown={(e)=>{if(e.key === "Enter") search(e.target.value);}}
              ></input>
               <button className="refresh" onClick={()=>{getAllUsersWithPagination (currentPage)}}>
                <i class="fa-solid fa-refresh"></i>
              </button>
            </div>
          </div>

         
            <div className="users-table">
              <table className="statsTable">
                <thead>
                  <tr>
                    <th>
                      <span className="sortBtn">
                        Name{" "}
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Username{" "}
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Email
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Role
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Points
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Level
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">
                        Blocked
                        <i className="fa-solid fa-arrow-down-wide-short"></i>
                      </span>
                    </th>
                    <th>
                      <span className="sortBtn">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td onClick={() => setSelectedUser(user)}>{user.name}</td>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{parseRole(user.role)}</td>
                      <td>{user.points}</td>
                      <td>{computeLevel(user.level)}
                        <br></br>
                        <progress className="progress-user-page" value={computeLevelPercentage(user.level)} max="100"/></td>
                      <td>{user.blocked ? "Yes" : "No"}</td>
                      <td>
                        <div className="actions-btns" style={{display:"flex",placeContent:"flex-start"}}>
                          <button
                            className="removeBtn"
                            onClick={() => deleteUser(user.id)}
                          >
                            <i className="fa-solid fa-user-minus"></i>
                          </button>
                          <button
                            className="blockBtn"
                            onClick={() => blockUser(user.id, !user.blocked)}
                          >
                            <i className="fa-solid fa-ban"></i>
                          </button>
                          {user.role != "EP" && user.role != "IP" ?<button className="promoteBtn" onClick={()=>{
                            setUserToPromote(user);
                            handleEditClick("promoteUser");
                          }}>
                            <i class="fa-solid fa-turn-up"></i>
                          </button> : <></>} 
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
              {currentPage > 1 ?<button className="previous-page-btn" onClick={prevPage}>
                <i className="fa-solid fa-caret-left"></i> Previous
              </button>: <></>}
              <p>Page {currentPage} of {totalPages}</p>
              {totalPages > currentPage ?<button className="next-page-btn" onClick={nextPage}>
                Next <i className="fa-solid fa-caret-right"></i>
              </button> : <></>}
              </div>
            </div>
          
        </div>
      </div>
      {isPopupVisible && (
        <PopUp
          type={popupType}
          currentUser={currentAuth.user}
          userToPromote={userToPromote}
          onClose={handleClosePopup}
        />
      )}
    </div>
    </>
    }
    </>
  );
}

export default UsersPage;
