import React, { useEffect, useState } from "react";
import "./events.css";
import { Events } from "../../../lib/api";
import PopUp from "./PopUp";


function EventsPage({currentUser}) {
  const eventsApi = new Events();

  const[events, setEvents] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [loading, setLoading] = useState(true);

  const [currentEvent, setCurrentEvent] = useState(null);


  async function getEvents() {
    setLoading(true);
    try {
      const reports = await eventsApi.getEvents();
      setEvents(reports.data);
    } catch (error) {
      console.error("Failed to get reports:", error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  const handleEditClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    getEvents();
  };


  function getConcluded(event){

    if(event.conclude){
      return "FINISHED"
    }
    else if (Date.parse(event.date) < new Date()){
      return "STARTED"
    }
    else{
      return "NOT STARTED"
    }
  }


  return (
    
      <>{loading ? 
            <div className="loading-screen">
            <i className="fa fa-spinner fa-spin"></i> 
            <p>Loading</p>
          </div>
         : <>   
          <div className="events-list">
    <div className="events-list-container">
      <div className="events-page-container">
        <div className="community-options">
          <h1>Events</h1>
          <div className="search-div">
            {/* <i className="fa-solid fa-magnifying-glass"></i>
            <input
              className="searchBar"
              type="search"
              placeholder="Search"
            ></input> */}
             <button className="refresh" onClick={()=>{getEvents()}}>
                <i class="fa-solid fa-refresh"></i>
              </button>
          </div>
        </div>

      
        <div className="events-table">
          <table className="statsTable">
            <thead>
              <tr>
                <th>
                  <button className="sortBtn">
                    Title
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
               
                <th>
                  <button className="sortBtn">
                    Date
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
                <th>
                  <button className="sortBtn">
                    Type
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
                <th>
                  <button className="sortBtn">
                    Description
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
                <th>
                  <button className="sortBtn">
                    Points
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
                <th>
                  <button className="sortBtn">
                    Status
                  </button>
                </th>
                <th>
                  <button className="sortBtn">
                    People
                    <i className="fa-solid fa-arrow-down-wide-short"></i>
                  </button>
                </th>
                <th>
                  <span className="sortBtn">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr key={index}>
                  <td>{event.title}</td>
                  <td>{event.date}</td>
                  <td>{event.type ? "COLLECT TRASH" : "PLANT TREES"}</td>
                  <td>{event.description}</td>
                  <td>{event.points}</td>
                  <td>{getConcluded(event)}</td>
                  <td>{event.participants}/{event.maxpeople}</td>
                  <td>
                      <div className="actions-btns">

                      <button onClick={async ()=>{
                        await setCurrentEvent(event);
                        handleEditClick()}}
                          className="conclueBtn"
                        >
                          <i class="fa-solid fa-check"></i>
                        </button>
                
                        <button onClick={async ()=>{
                          await eventsApi.deleteEvent(event.id);
                          getEvents();
                        }}
                          className="removeBtn"
                        >
                          <i class="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="pagination">
            <button className="previous-page-btn">
              <i className="fa-solid fa-caret-left"></i> Previous
            </button>
            <p>Page 1 of 2</p>
            <button className="next-page-btn">
              Next <i className="fa-solid fa-caret-right"></i>
            </button>
          </div> */}
        </div>
      </div>
    </div>
    {isPopupVisible && (
        <PopUp
          type={"finishEvent"}
          currentUser={currentUser}
          currentEvent={currentEvent}
          onClose={handleClosePopup}
        />
      )}
  </div>
  </>}
  </>

  );
}

export default EventsPage;
