import React from "react";
import "./communities.css";
import { Communities, Users } from "../../../lib/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PopUp from "./PopUp";
import SelectedCommunity from "./SelectedCommunity";

function CommunitiesPage({ currentUser }) {
  const usersApi = new Users();
  const communitiesApi = new Communities();
  const navigate = useNavigate();

  const [communities, setCommunities] = useState([]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const communitiesPerPage = 8;

  async function getAllCommunitiesWithPagination(newPage) {
    setLoading(true);
    try {
      const response = await communitiesApi.getAllCommunitiesWithPagination(
        newPage,
        communitiesPerPage
      );
      const {
        communities: newCommunities,
        currentPage: cPage,
        totalPages: tPages,
      } = response.data;

      setCommunities(newCommunities);
      setCurrentPage(cPage);
      setTotalPages(tPages);
    } catch (error) {
      console.error("Failed to get communities:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllCommunitiesWithPagination(currentPage);
  }, []);

  function openSelectedCommunity(community) {
    navigate("/selected-community", {
      state: { selectedCommunity: community, currentUser: currentUser },
    });
  }

  const handleEditClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    getAllCommunitiesWithPagination(currentPage);
  };

  function nextPage() {
    getAllCommunitiesWithPagination(currentPage + 1);
  }

  function prevPage() {
    getAllCommunitiesWithPagination(currentPage - 1);
  }

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <i className="fa fa-spinner fa-spin"></i>
          <p>Loading</p>
        </div>
      ) : (
        <>
          <div className="communities-list">
            <div className="communities-list-container">
              <div className="communities-page-container">
                <div className="community-options">
                  <h1>Communities</h1>
                  <div className="search-div">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      className="searchBar"
                      type="search"
                      placeholder="Search"
                    ></input>
                    <button
                      className="refresh"
                      onClick={() => {
                        getAllCommunitiesWithPagination(currentPage);
                      }}
                    >
                      <i class="fa-solid fa-refresh"></i>
                    </button>
                    <button className="addUserBtn" onClick={handleEditClick}>
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div className="communities-table">
                  <table className="statsTable">
                    <thead>
                      <tr>
                        <th>
                          <button className="sortBtn">
                            Name{" "}
                            <i className="fa-solid fa-arrow-down-wide-short"></i>
                          </button>
                        </th>
                        <th>
                          <button className="sortBtn">
                            Description{" "}
                            <i className="fa-solid fa-arrow-down-wide-short"></i>
                          </button>
                        </th>
                        <th>
                          <button className="sortBtn">
                            Members{" "}
                            <i className="fa-solid fa-arrow-down-wide-short"></i>
                          </button>
                        </th>
                        <th>
                          <button className="sortBtn">
                            Points{" "}
                            <i className="fa-solid fa-arrow-down-wide-short"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {communities.map((community, index) => (
                        <tr
                          key={index}
                          onClick={() => openSelectedCommunity(community)}
                        >
                          <td className="td-name">
                            <i
                              style={{ color: "#3bce8e" }}
                              class="fa-solid fa-arrow-up-right-from-square"
                            ></i>
                            &nbsp;&nbsp;&nbsp;
                            {community.name}
                          </td>
                          <td>{community.description}</td>
                          <td>{community.participants}</td>
                          <td>{community.points}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination">
                    {currentPage > 1 ? (
                      <button className="previous-page-btn" onClick={prevPage}>
                        <i className="fa-solid fa-caret-left"></i> Previous
                      </button>
                    ) : (
                      <></>
                    )}
                    <p>
                      Page {currentPage} of {totalPages}
                    </p>
                    {totalPages > currentPage ? (
                      <button className="next-page-btn" onClick={nextPage}>
                        Next <i className="fa-solid fa-caret-right"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {isPopupVisible && (
              <PopUp
                type={"addCommunity"}
                currentUser={currentUser}
                onClose={handleClosePopup}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default CommunitiesPage;
