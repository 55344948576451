import './CubeLoader.css'

function CubeLoader(){


    return(<div className="cube-loader">
    
    <div className="loader-boxes">
    <div className="loader-box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="loader-box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="loader-box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="loader-box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
    
    </div>);

}

export default CubeLoader