import React, { useState, useEffect, useContext } from "react";
import SideMenu from "./Components/SideMenu.jsx";
import UsersPage from "./Components/UsersPage.jsx";
import CommunitiesPage from "./Components/CommunitiesPage.jsx";
import EventsPage from "./Components/EventsPage.jsx";
import { Auth, Reports } from "../../lib/api";
import ReportsPage from "./Components/ReportsPage.jsx";
import Dashboard from "./Components/Dashboard.jsx";
import "./systemManagement.css";
import ReportsMap from "./Components/ReportsMap.jsx";
import AccountPage from "./Components/AccountPage.jsx";
import { AuthContext } from "../../App.js";
import PartnersPage from "./Components/PartnersPage.jsx";
import CouponsPage from "./Components/CouponsPage.jsx";
import { useNavigate } from "react-router-dom";

function SystemManagement() {
  const auth = new Auth();
  const reportsApi = new Reports();
  const currentAuth = useContext(AuthContext);

  var currUser = currentAuth.user;

  const [currUserCommunities, setCurrUserCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [reports, setReports] = useState([]);
  const [loadingUC, setLoadingUC] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);

  useEffect(() => {
    if (currentAuth && currentAuth.user) {
      setLoading(false);
    } else {
      return;
    }

    let currUserRole = currentAuth.user.role;

    if (
      window.location.pathname === "/system-management" ||
      window.location.pathname === "/system-management/"
    ) {
      switch (currUserRole) {
        case "GC":
          handleIndexChange(2);
          break;
        case "EP":
          handleIndexChange(5);
          break;
        case "USER":
        case "IP":
          handleIndexChange(7);
          break;
        default:
          handleIndexChange(0);
          break;
      }
    }
  }, [currentAuth]);

  useEffect(() => {
    if (!loading) {
      // setActiveIndex(hasAccess() ? 0 : 5);
    }
  }, [loading]);

  useEffect(() => {
    getAllReports();
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/system-management") {
      setActiveIndex(0);
    } else if (window.location.pathname === "/system-management/users") {
      setActiveIndex(1);
    } else if (window.location.pathname === "/system-management/communities") {
      setActiveIndex(2);
    } else if (window.location.pathname === "/system-management/events") {
      setActiveIndex(3);
    } else if (window.location.pathname === "/system-management/reports") {
      setActiveIndex(4);
    } else if (window.location.pathname === "/system-management/partners") {
      setActiveIndex(5);
    } else if (window.location.pathname === "/system-management/map") {
      setActiveIndex(6);
    } else if (window.location.pathname === "/system-management/account") {
      setActiveIndex(7);
    }
  }, [window.location.pathname, loading]);

  async function getAllReports() {
    setLoadingUC(true);
    try {
      const response = await reportsApi.getAllReports();
      setReports(response.data);
      return response.data;
    } catch (error) {
      console.error("Failed to get user reports:", error);
    } finally {
      setLoadingUC(false);
    }
  }

  function hasAccess() {
    let currUserRole = currentAuth.user.role;

    switch (activeIndex) {
      case 0:
        return (
          currUserRole === "GBO" ||
          currUserRole === "GS" ||
          currUserRole === "SYSADMIN"
        );
      case 1:
        return currUserRole === "GS" || currUserRole === "SYSADMIN";
      case 2:
        return (
          currUserRole === "GBO" ||
          currUserRole === "GC" ||
          currUserRole === "GS" ||
          currUserRole === "SYSADMIN"
        );
      case 3:
        return (
          currUserRole === "GBO" ||
          currUserRole === "GS" ||
          currUserRole === "SYSADMIN"
        );
      case 4:
        return (
          currUserRole === "GBO" ||
          currUserRole === "GS" ||
          currUserRole === "SYSADMIN"
        );
      case 5:
        return (
          currUserRole === "GS" ||
          currUserRole === "GBO" ||
          currUserRole == "EP" ||
          currUserRole === "SYSADMIN"
        );
      case 6:
        return true;
      case 7:
        return true;
      case 8:
        return true;
      default:
        return false;
    }
  }

  const navigate = useNavigate();

  function handleIndexChange(index) {
    setActiveIndex(index);
    navigate(
      `/system-management/${
        index === 0
          ? ""
          : index === 1
          ? "users"
          : index === 2
          ? "communities"
          : index === 3
          ? "events"
          : index === 4
          ? "reports"
          : index === 5
          ? "partners"
          : index === 6
          ? "map"
          : index === 7
          ? "account"
          : ""
      }`
    );
  }

  const renderPage = (index) => {
    switch (index) {
      case 0:
        return <Dashboard />;
      case 1:
        return <UsersPage />;
      case 2:
        return <CommunitiesPage currentUser={currUser} />;
      case 3:
        return <EventsPage />;
      case 4:
        return <ReportsPage currentUser={currUser} />;
      case 5:
        return currUser.role == "EP" ? <CouponsPage /> : <PartnersPage />;
      case 6:
        return <ReportsMap reportsData={reports} />;
      case 7:
        return (
          <AccountPage
            currentUser={currUser}
            currentUserCommunities={currUserCommunities}
          />
        );
      case 8:
        auth.logout();
        window.location.reload();
        return null;
      default:
        return <UsersPage />;
    }
  };

  return (
    <div className="app-container">
      {loading ? (
        <div className="loading-screen">
          <i className="fa fa-spinner fa-spin"></i>
          <p>Loading</p>
        </div>
      ) : (
        <>
          <SideMenu
            activeIndex={activeIndex}
            setActiveIndex={(index) => {
              handleIndexChange(index);
            }}
            currRole={currentAuth.user.role}
          />
          {!hasAccess() ? (
            <div className="access-denied">
              <i className="fa-solid fa-lock"></i>
              <h1>Access Denied</h1>
              <p>You do not have access to this page.</p>
            </div>
          ) : (
            <div className="page-content">
              <div className="page-inner-content">
                {renderPage(activeIndex)}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SystemManagement;
