import React, { useContext, useEffect, useState } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { Communities, Reports, Stats, Users } from "../../../lib/api";
import Graph from "./Graph";
import { AuthContext } from "../../../App";

function Dashboard() {
  const navigate = useNavigate();
  const usersApi = new Users();
  const reportsApi = new Reports();
  const statsApi = new Stats();

  const currentAuth = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [reports, setReports] = useState([]);
  const [stats, setStats] = useState([]);

  const[loadingStats, setLoadingStats] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingReports, setLoadingReports] = useState(true);

  async function getStats() {
    try {
      const response = await statsApi.getStats();
      setStats(response.data);
      setLoadingStats(false);
    } catch (error) {
      console.error("Failed to get reports:", error);
    }
  }

  async function getAllUsers() {
    try {
      const response = await usersApi.getAllUsers();
      setUsers(JSON.stringify(response.data));
      setLoadingUsers(false);
    } catch (error) {
      console.error("Failed to get users:", error);
    }
  }

  async function getAllReports() {
    try {
      const response = await reportsApi.getAllReports();
      setReports(JSON.stringify(response.data));
      setLoadingReports(false);
    } catch (error) {
      console.error("Failed to get reports:", error);
    }
  }

  useEffect(() => {
    getStats();
    getAllUsers();
    getAllReports();
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <h1>Dashboard</h1>
        <div className="search-div">
          <i className="fa-solid fa-smile"></i>
          <p>Welcome back, {currentAuth.user.name}!</p>
        </div>
      </div>

      {loadingStats ? 
            <div className="loading-screen">
            <i className="fa fa-spinner fa-spin"></i> 
            <p>Loading</p>
          </div>
          : <>

      <div className="stats-grid-container">
        <div className="stats-box">
          <h2>{stats.users}</h2>
          <p>Users Registered</p>
        </div>
        <div className="stats-box">
          <h2>{stats.communities}</h2>
          <p>Communities Created</p>
        </div>
        <div className="stats-box">
          <h2>{stats.reports}</h2>
          <p>Reports Created</p>
        </div>
        <div className="stats-box">
          <h2>{stats.events}</h2>
          <p>Events Created</p>
        </div>
        <div className="stats-box">
          <h2>{stats.kgCO2} Kg</h2>
          <p>CO2 emissions reduced</p>
        </div>
        <div className="stats-box">
          <h2>{stats.points}</h2>
          <p>Total Points</p>
        </div>
        <div className="stats-box">
          <h2>{stats.partners}</h2>
          <p>Partners</p>
        </div>
        <div className="stats-box">
          <h2>0</h2>
          <p>Redeemed Coupons</p>
        </div>
      </div>
      </>}

      <div className="graphs-grid-container">
      {loadingUsers ? 
            <div className="loading-screen">
            <i className="fa fa-spinner fa-spin"></i> 
            <p>Loading</p>
          </div>
          : 
        <div className="graph-box">
          <h2>Users Registered</h2>
          <Graph data={users} type={"users"} />
        </div>}
        {loadingReports ? 
            <div className="loading-screen">
            <i className="fa fa-spinner fa-spin"></i> 
            <p>Loading</p>
          </div>
          : 
        <div className="graph-box">
          <h2>Reports Created</h2>
          <Graph data={reports} type={"reports"} />
        </div>}
      </div>

    </div>
  );
}

export default Dashboard;
